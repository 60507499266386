/**
 * Map of 1024 by 1024 vector paths
 */

export const ICONS = {
  discord:
    "M220.674 259.166C274.974 234.248 333.21 215.888 394.092 205.376 395.202 205.172 396.312 205.682 396.882 206.696 404.37 220.016 412.668 237.392 418.476 251.048 483.96 241.244 549.114 241.244 613.254 251.048 619.068 237.086 627.666 220.016 635.184 206.696 635.76 205.712 636.864 205.208 637.974 205.376 698.826 215.858 757.056 234.212 811.398 259.166 811.866 259.364 812.268 259.706 812.538 260.144 922.992 425.162 953.25 586.124 938.406 745.088 938.34 745.862 937.902 746.606 937.296 747.08 864.42 800.6 793.83 833.09 724.548 854.624 723.444 854.96 722.268 854.558 721.56 853.646 705.174 831.266 690.564 807.668 678.036 782.852 677.298 781.394 678.006 779.672 679.518 779.096 702.69 770.306 724.752 759.59 745.974 747.422 747.654 746.438 747.792 744.038 746.244 742.886 741.78 739.544 737.31 736.058 733.044 732.542 732.276 731.9 731.202 731.768 730.29 732.17 590.856 795.83 439.902 795.83 302.112 732.17 301.206 731.732 300.132 731.87 299.322 732.512 295.056 736.028 290.592 739.544 286.092 742.886 284.55 744.038 284.646 746.438 286.326 747.422 307.554 759.824 329.616 770.306 352.824 779.066 354.33 779.636 355.002 781.394 354.264 782.852 341.472 807.632 326.862 831.23 310.776 853.61 310.104 854.558 308.898 854.96 307.788 854.624 238.17 833.09 167.58 800.6 94.704 747.08 94.068 746.606 93.66 745.898 93.594 745.118 75.828 561.338 123.348 401.696 219.564 260.18 219.798 259.706 220.2 259.364 220.674 259.166ZM657.216 648.296C699.198 648.296 733.788 609.752 733.788 562.424 733.788 515.09 699.864 476.552 657.216 476.552 614.232 476.552 579.978 515.426 580.65 562.424 580.65 609.752 614.568 648.296 657.216 648.296ZM374.112 648.296C416.094 648.296 450.684 609.752 450.684 562.424 450.684 515.09 416.766 476.552 374.112 476.552 331.128 476.552 296.874 515.426 297.546 562.424 297.546 609.752 331.128 648.296 374.112 648.296Z",
  github:
    "M425.25 109c-234.809 0-425.25 190.406-425.25 425.25 0 187.89 121.834 347.287 290.836 403.527 21.227 3.934 28.102-9.249 28.102-20.447v-79.167c-118.29 25.728-142.919-50.179-142.919-50.179-19.349-49.152-47.238-62.228-47.238-62.228-38.591-26.401 2.941-25.834 2.941-25.834 42.702 2.977 65.17 43.836 65.17 43.836 37.918 64.992 99.473 46.21 123.748 35.331 3.792-27.464 14.813-46.246 27.003-56.842-94.441-10.808-193.737-47.274-193.737-210.18 0-46.459 16.62-84.377 43.801-114.144-4.394-10.738-18.959-54.007 4.146-112.55 0 0 35.721-11.411 116.979 43.588 33.914-9.426 70.273-14.14 106.419-14.317 36.146.177 72.541 4.89 106.525 14.317 81.187-54.999 116.837-43.588 116.837-43.588 23.141 58.578 8.576 101.847 4.182 112.55 27.287 29.767 43.765 67.721 43.765 114.144 0 163.331-99.473 199.3-194.162 209.825 15.238 13.183 29.165 39.052 29.165 78.742v116.696c0 11.305 6.804 24.594 28.385 20.412 168.86-56.31 290.552-215.673 290.552-403.491 0-234.844-190.406-425.25-425.25-425.25z",
  chevronLeft:
    "M662.827 874.027L300.8 512L662.827 149.973L723.2 210.304L421.461 512L723.2 813.696L662.827 874.027V874.027Z",
  chevronRight:
    "M361.173 149.973L723.2 512L361.173 874.027L300.8 813.696L602.539 512L300.8 210.304L361.173 149.973V149.973Z",
  telegram:
    "M417.28 795.733L429.227 615.253L756.907 320C771.413 306.773 753.92 300.373 734.72 311.893L330.24 567.467L155.307 512C117.76 501.333 117.333 475.307 163.84 456.533L845.227 193.707C876.373 179.627 906.24 201.387 894.293 249.173L778.24 795.733C770.133 834.56 746.667 843.947 714.24 826.027L537.6 695.467L452.693 777.813C442.88 787.627 434.773 795.733 417.28 795.733V795.733Z",
  youtube:
    "M426.667 640L648.107 512L426.667 384V640ZM919.893 305.92C925.44 325.973 929.28 352.853 931.84 386.987C934.827 421.12 936.107 450.56 936.107 476.16L938.667 512C938.667 605.44 931.84 674.133 919.893 718.08C909.227 756.48 884.48 781.227 846.08 791.893C826.027 797.44 789.333 801.28 733.013 803.84C677.547 806.827 626.773 808.107 579.84 808.107L512 810.667C333.227 810.667 221.867 803.84 177.92 791.893C139.52 781.227 114.773 756.48 104.107 718.08C98.5599 698.027 94.7199 671.147 92.1599 637.013C89.1733 602.88 87.8932 573.44 87.8932 547.84L85.3333 512C85.3333 418.56 92.1599 349.867 104.107 305.92C114.773 267.52 139.52 242.773 177.92 232.107C197.973 226.56 234.667 222.72 290.987 220.16C346.453 217.173 397.227 215.893 444.16 215.893L512 213.333C690.773 213.333 802.133 220.16 846.08 232.107C884.48 242.773 909.227 267.52 919.893 305.92V305.92Z",
  instagram:
    "M332.8 85.3333H691.2C827.733 85.3333 938.667 196.267 938.667 332.8V691.2C938.667 756.832 912.594 819.776 866.185 866.185C819.776 912.594 756.832 938.667 691.2 938.667H332.8C196.267 938.667 85.3334 827.733 85.3334 691.2V332.8C85.3334 267.168 111.406 204.224 157.815 157.815C204.224 111.406 267.168 85.3333 332.8 85.3333V85.3333ZM324.267 170.667C283.529 170.667 244.461 186.849 215.655 215.655C186.85 244.461 170.667 283.529 170.667 324.267V699.733C170.667 784.64 239.36 853.333 324.267 853.333H699.733C740.471 853.333 779.539 837.151 808.345 808.345C837.151 779.539 853.333 740.471 853.333 699.733V324.267C853.333 239.36 784.64 170.667 699.733 170.667H324.267ZM736 234.667C750.145 234.667 763.711 240.286 773.712 250.288C783.714 260.29 789.333 273.855 789.333 288C789.333 302.145 783.714 315.71 773.712 325.712C763.711 335.714 750.145 341.333 736 341.333C721.855 341.333 708.29 335.714 698.288 325.712C688.286 315.71 682.667 302.145 682.667 288C682.667 273.855 688.286 260.29 698.288 250.288C708.29 240.286 721.855 234.667 736 234.667ZM512 298.667C568.58 298.667 622.842 321.143 662.85 361.151C702.857 401.158 725.333 455.421 725.333 512C725.333 568.58 702.857 622.842 662.85 662.849C622.842 702.857 568.58 725.333 512 725.333C455.421 725.333 401.158 702.857 361.151 662.849C321.143 622.842 298.667 568.58 298.667 512C298.667 455.421 321.143 401.158 361.151 361.151C401.158 321.143 455.421 298.667 512 298.667V298.667ZM512 384C478.052 384 445.495 397.486 421.49 421.49C397.486 445.495 384 478.052 384 512C384 545.948 397.486 578.505 421.49 602.51C445.495 626.514 478.052 640 512 640C545.948 640 578.505 626.514 602.51 602.51C626.514 578.505 640 545.948 640 512C640 478.052 626.514 445.495 602.51 421.49C578.505 397.486 545.948 384 512 384Z",
  facebook:
    "M213.333 128H810.667C833.298 128 855.003 136.99 871.006 152.994C887.01 168.997 896 190.702 896 213.333V810.667C896 833.298 887.01 855.003 871.006 871.006C855.003 887.01 833.298 896 810.667 896H213.333C190.702 896 168.997 887.01 152.994 871.006C136.99 855.003 128 833.298 128 810.667V213.333C128 190.702 136.99 168.997 152.994 152.994C168.997 136.99 190.702 128 213.333 128V128ZM768 213.333H661.333C621.728 213.333 583.744 229.067 555.739 257.072C527.733 285.077 512 323.061 512 362.667V469.333H426.667V597.333H512V896H640V597.333H768V469.333H640V384C640 372.684 644.495 361.832 652.497 353.83C660.498 345.829 671.351 341.333 682.667 341.333H768V213.333Z",
  twitter:
    "M958.293 256C925.44 270.933 890.027 280.747 853.333 285.44C890.88 262.827 919.893 226.987 933.547 183.893C898.133 205.227 858.88 220.16 817.493 228.693C783.787 192 736.427 170.667 682.667 170.667C582.4 170.667 500.48 252.587 500.48 353.707C500.48 368.213 502.187 382.293 505.173 395.52C353.28 387.84 218.027 314.88 128 204.373C112.213 231.253 103.253 262.827 103.253 296.107C103.253 359.68 135.253 416 184.747 448C154.453 448 126.293 439.467 101.547 426.667V427.947C101.547 516.693 164.693 590.933 248.32 607.573C221.471 614.921 193.284 615.943 165.973 610.56C177.562 646.932 200.258 678.759 230.87 701.565C261.483 724.371 298.472 737.011 336.64 737.707C271.942 788.925 191.744 816.611 109.227 816.213C94.72 816.213 80.2133 815.36 65.7067 813.653C146.773 865.707 243.2 896 346.453 896C682.667 896 867.413 616.96 867.413 375.04C867.413 366.933 867.413 359.253 866.987 351.147C902.827 325.547 933.547 293.12 958.293 256V256Z",
  tiktok:
    "M958.293 256C925.44 270.933 890.027 280.747 853.333 285.44C890.88 262.827 919.893 226.987 933.547 183.893C898.133 205.227 858.88 220.16 817.493 228.693C783.787 192 736.427 170.667 682.667 170.667C582.4 170.667 500.48 252.587 500.48 353.707C500.48 368.213 502.187 382.293 505.173 395.52C353.28 387.84 218.027 314.88 128 204.373C112.213 231.253 103.253 262.827 103.253 296.107C103.253 359.68 135.253 416 184.747 448C154.453 448 126.293 439.467 101.547 426.667V427.947C101.547 516.693 164.693 590.933 248.32 607.573C221.471 614.921 193.284 615.943 165.973 610.56C177.562 646.932 200.258 678.759 230.87 701.565C261.483 724.371 298.472 737.011 336.64 737.707C271.942 788.925 191.744 816.611 109.227 816.213C94.72 816.213 80.2133 815.36 65.7067 813.653C146.773 865.707 243.2 896 346.453 896C682.667 896 867.413 616.96 867.413 375.04C867.413 366.933 867.413 359.253 866.987 351.147C902.827 325.547 933.547 293.12 958.293 256V256Z",
};
